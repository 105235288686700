import { Component } from "react"
import { css } from '@emotion/react'
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'

// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'

//ICONS MATERIAL UI
// import workAfiImg from '../../../assets/images/footers/ban_trabaja.jpg'
import Kaiser from '../../../assets/images/footers/kaiserPermanente.png'
import Delta from '../../../assets/images/footers/deltaDental.png'
import Guardian from '../../../assets/images/footers/Guardian.png'
import K401 from '../../../assets/images/footers/401K.png'

 
const iframeCss= css`            
            width: 100%; 
            height: 550px; 
            border: none;
            `

 const imgCss = css` 
 text-align: center; 
 `
 const imageCss = css`  
 @media(min-width: 360px ){
                width:300px;                
            }
            @media(min-width: 768px ){
                width:50%;                 
            }
            @media(min-width: 960px ){
                width:70%;                
            }
 `
class WorkAreCmp extends Component {    

    render() {
        const {            
            titleIcon='Recursos Humanos',
            txtTitleWorkAre=<span style={{color:"#698d21"}}><strong>Trabaja Con Nosotros</strong></span>,
            txtCntWorkAre=<span>
                                En Apoyo Financiero tenemos la convicción que nuestro éxito depende en su totalidad de NUESTRO PERSONAL. Es por eso que Apoyo Financiero genuinamente cuida, valora, entrena y recompensa a sus empleados ofreciéndoles una experiencia laboral única y dinámica que les permite crecer profesionalmente usando su potencial, su experiencia, su creatividad y sus propios valores.
                        </span>,
            txtCntWorkAreTwo=<span>
                                Apoyo Financiero desarrolla continuamente el TALENTO de su personal buscando su crecimiento a corto, mediano y largo plazo. El 98% de nuestras oportunidades de crecimiento y liderazgo se dan internamente.<br/><br/>
                                Algunas de las razones del porque la gente nos selecciona como empleador:
                                <ul>
                                    <li>Somos una empresa estable y en constante crecimiento</li>
                                    <li>Creemos y vivimos de acuerdo a los valores que marcan nuestra cultura organizacional</li>
                                    <li>Nuestros empleados son valorados y respetados</li>
                                    <li>Recompensamos siempre el buen desempeño</li>
                                    <li>Somos una empresa que busca desarrollar el talento de su gran personal</li>
                                    <li>Buscamos ofrecer el mejor servicio a nuestros clientes tanto externos como internos</li>
                                    <li>Trabajamos en equipo en un ambiente sano, divertido e innovador</li>
                                    <li>Ofrecemos sueldos y salarios competitivos y excelentes beneficios (Médico, Dental, Visión, Seguro de Vida, Plan para el Retiro 401 (K)</li>                                    
                                </ul>
                                Te invitamos a aplicar si estás listo a llevar tu carrera profesional al siguiente nivel - <a href="mailto:careers@apoyofin.com" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(55 72 20)"}}>¡APLICA!</a><br/><br/>                                
                        </span>,
            txtTitleBenefits=<span style={{color:"rgb(213 28 28)"}}><strong>Beneficios</strong></span>,
            txtCntBenefits=<span>
                            En Apoyo Financiero valoramos el compromiso, el esfuerzo y la disciplina diaria de todo nuestro personal. Estamos comprometidos en proveer los mejores beneficios, los cuales reflejan el valor que le damos a todos y a cada uno de nuestros talentos.
                            </span>,
            txtGMedicos=<span>
                            <span style={{color:"rgb(213 28 28)"}}>
                                <strong>Seguro de Gastos Médicos</strong>
                            </span><br/>
                            Apoyo Financiero cubre el 100% de su cobertura médica.
                        </span>,
            txtDental=<span>
                        <span style={{color:"rgb(213 28 28)"}}>
                            <strong>Dental</strong>
                        </span><br/>
                        Delta Dental
                    </span>,
            txtVida=<span>
                        <span style={{color:"rgb(213 28 28)"}}>
                        <strong>Seguro de Vida Y Vision</strong>
                        </span><br/>
                        Guardian
                    </span>,
            txtAhorro=<span style={{color:"rgb(213 28 28)"}}>
                        <strong>Plan de Ahorro Para El Retiro</strong><br/>                        
                    </span>,
            txtOthers=<span>
                            <span style={{color:"rgb(213 28 28)",fontSize:"20px"}}>
                                <strong>Otros beneficios</strong>
                            </span>
                            <ol>
                                <li>Trabajo de Tiempo Completo (40 horas a la semana, día de descanso los domingos y otro día entre semana)</li>
                                <li>Entrenamiento y Capacitación Contínua</li>
                                <li>Dias de Vacaciones Pagados</li>
                                <li>9 Días Festivos Pagados</li>
                                <li>Días de Enfermedad Pagados (tres veces más que lo requiere la ley)</li>
                                <li>3 Días de Luto Pagados</li>
                                <li>Campañas de Salud</li>
                                <li>Retos y Concursos entre Sucursales</li>
                                <li>Viernes y Sábado Casual</li>
                            </ol>
                        </span>,
            txtTitleImpact=<span style={{color:"rgb(213 28 28)"}}><strong>Impacto</strong></span>,
            txtCntImpact=<span>
                            Conoce más sobre el impacto que ha tenido nuestra compañia.
                        </span>,
            txtTitleVacants=<span style={{color:"rgb(213 28 28)"}}><strong>Vacantes</strong></span>
            
            } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>                        
                        <Typography component='h3' variant="h4">
                        <strong style={{color:"#698d21"}}>{titleIcon}</strong>                        
                        </Typography>
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>                    
                    <br/><br/>
                    <Grid item xs={12} sm={12}>
                        <Typography component='h6' variant="h6" color="primary">
                            <strong>{txtTitleWorkAre}</strong>                        
                        </Typography>
                        <br/>
                        <Typography component='subtitle' variant="subtitle" >
                            {txtCntWorkAre}                        
                        </Typography>
                    </Grid>
                    <br/>
                    {/* <Grid item xs={12} sm={12}>
                        <div css={imgCss}>
                            <img src={workAfiImg} alt="imgAppAfi" css={imageCss} />
                        </div>                        
                    </Grid>
                    <br/> */}
                    <Grid item xs={12} sm={12}>
                        <Typography component='subtitle' variant="subtitle" >
                            {txtCntWorkAreTwo}                        
                        </Typography>
                        
                        <hr/>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography component='h6' variant="h6" color="primary">
                            <strong>{txtTitleBenefits}</strong>                        
                        </Typography>
                        <br/>
                        <Typography component='subtitle' variant="subtitle" >
                            {txtCntBenefits}                        
                        </Typography>
                        <br/>&nbsp;
                        <hr/>
                    </Grid>

                   <Grid container spacing={3}>
                     <Grid item xs={12} sm={6}>
                        <div css={imgCss}>
                            <img src={Kaiser} alt="img-kaiser-gastos" css={imageCss}/>
                            <br/>
                            {txtGMedicos} 
                        </div>                        
                    </Grid>
                    
                     <Grid item xs={12} sm={6} >
                        <div css={imgCss}>
                            <img src={Delta} alt="img-Delta-dental" css={imageCss}/>
                            <br/>
                            {txtDental}
                        </div>                        
                    </Grid>
                    
                     <Grid item xs={12} sm={6}>
                        <div css={imgCss}>
                            <img src={Guardian} alt="img-Guardian" css={imageCss}/>
                            <br/>
                            {txtVida}
                        </div>                         
                    </Grid>
                    
                     <Grid item xs={12} sm={6}>
                        <div css={imgCss}>
                            <img src={K401} alt="img-Plan-ahorro-para-el-retiro" css={imageCss}/>
                            <br/>
                            {txtAhorro}
                        </div>                         
                    </Grid>
                    

                    </Grid>
                    <hr/>
                    <Grid item xs={12} sm={12}>
                        {txtOthers}
                        <br/>&nbsp;
                        <hr/>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography component='h6' variant="h6" color="primary">
                            <strong>{txtTitleImpact}</strong>                        
                        </Typography>
                        <br/>
                        <Typography component='subtitle' variant="subtitle" >
                            {txtCntImpact}                        
                        </Typography>
                        <br/>&nbsp;
                        <hr/>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography component='h6' variant="h6" color="primary">
                            <strong>{txtTitleVacants}</strong>                        
                        </Typography>
                        <br/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <iframe src="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=64FE544593519BC5C9E49FA9FB48BC43" css={iframeCss} rel="nofollow noopener noreferrer"></iframe>
                    </Grid>
                        
                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

WorkAreCmp.propTypes = {          
    titleIcon: PropTypes.string,
    txtTitleWorkAre: PropTypes.string,
    txtCntWorkAre: PropTypes.string,
    txtCntWorkAreTwo: PropTypes.string,
    txtTitleBenefits: PropTypes.string,
    txtCntBenefits: PropTypes.string,
    txtGMedicos: PropTypes.string,
    txtDental: PropTypes.string,
    txtVida: PropTypes.string,
    txtAhorro: PropTypes.string,
    txtOthers: PropTypes.string,
    txtTitleImpact: PropTypes.string,
    txtCntImpact: PropTypes.string,
    txtTitleVacants: PropTypes.string
}

export default withThemeProps(WorkAreCmp, 'VDPNWorkAre')






